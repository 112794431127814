import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PollIcon from '@mui/icons-material/Poll';
import {
  Box, Card, CardContent, Divider,
  Grid, InputAdornment,
  Tab,
  Tabs, TextField
} from '@mui/material';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useState } from 'react';
import Button from '../../../components/Button';
import EditableTable from '../../../components/EditableTable';
import TableFilterContainer from '../../../components/EditableTable/TableFilterContainer';
import {
  useGetExternalLogisticStatisticsTableQuery,
  useGetInternalLogisticStatisticsTableQuery,
  useGetLogisticStatisticsTotalQuery
} from '../../../store/slices/financeSlice';
import { stringifyLocationSearch } from '../../../utils/locationSearch';
import { validateDatePickerValueCopy } from '../../../utils/setDateValue';
import CardStatistics from '../components/CardStatistics';
import { columns } from './columns';
import { EXTERNAL_TAB, INTERNAL_TAB } from './constants';
import InvoicesDrawer from './InvoicesDrawer';
import {
  DatePickerFilterStyle,
  FilterContainer,
  StyledTableFilter
} from "../../../components/EditableTable/TableFilterContainer/style";
import {CloseIcon} from "../../../components/Icons";
import {CloseButton} from "../../Logistics/style";
import DatePicker from "../../../form/components/DatePicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {debounce} from "lodash";
import SearchIcon from "@mui/icons-material/Search";

const LogisticStatistics = () => {
  const [dateFromFilter, setDateFromFilter] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateToFilter, setDateToFilter] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [typeFilter, setTypeFilter] = useState(null);
  const [search, setSearch] = useState(null);
  const [activeTab, setActiveTab] = useState('external');
  const [refresh, setRefresh] = useState(0);
  const [activeOrderId, setActiveOrderId] = useState(null);

  const { data: dataTotal } = useGetLogisticStatisticsTotalQuery(stringifyLocationSearch({
    delivery_date_from: dateFromFilter,
    delivery_date_to: dateToFilter,
  }), {
    refetchOnMountOrArgChange: true
  });

  const filterConfig = {
    items: [
      {
        value: validateDatePickerValueCopy(dateFrom || null),
        callback: (e) => {
          setDateFrom(validateDatePickerValueCopy(e));
          setDateFromFilter(e ? moment(e).format('DD-MM-YYYY') : '');
        },
        closeCallback: () => {
          setDateFrom(null);
          setDateFromFilter(null);
        },
        typeFilter: 'DatePicker',
        icon: 'CalendarMonthIcon',
        placeholder: 'Date from'
      },
      {
        value: validateDatePickerValueCopy(dateTo || null),
        callback: (e) => {
          setDateTo(validateDatePickerValueCopy(e));
          setDateToFilter(e ? moment(e).format('DD-MM-YYYY') : '');
        },
        closeCallback: () => {
          setDateTo(null);
          setDateToFilter(null);
        },
        typeFilter: 'DatePicker',
        icon: 'CalendarMonthIcon',
        placeholder: 'Date to'
      },
    ]
  };
  const handleRefresh = () => {
    setRefresh(!refresh);
  };
  const handleChangeTab = (_, value) => {
    setActiveTab(value);
    handleRefresh();
  };
  const handleInvoicesDrawer = (value) => {
    setActiveOrderId(value);
  };

  const setDateFilter = (value) => {
    let dateFrom = null;

    if (value === 'week') {
      dateFrom = moment().subtract(7, 'days');
    }
    if (value === 'month') {
      dateFrom = moment().subtract(31, 'days');
    }
    if (value === 'year') {
      dateFrom = moment().subtract(365, 'days');
    }

    if (dateFrom) {
      setDateFrom(validateDatePickerValueCopy(dateFrom));
      setDateFromFilter(dateFrom.format('DD-MM-YYYY'));
      const dateTo = moment();
      setDateTo(validateDatePickerValueCopy(dateTo));
      setDateToFilter(dateTo.format('DD-MM-YYYY'));
    }
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card>
          <CardContent sx={FilterContainer}>
            <Grid container spacing={6}>
              {
                filterConfig.items?.filter(item => !!item).map(({value = '', callback = () => {}, closeCallback = () => {}, placeholder = ''}) => (
                  <Grid item xs={12} md={2} sx={StyledTableFilter}>
                    <CalendarMonthIcon sx={{ ...(!!value && { color: 'green' }) }} />
                    <DatePicker
                      value={value || ''}
                      sx={DatePickerFilterStyle}
                      placeholderText={placeholder || 'Search'}
                      dateFormat='dd.MM.yyyy'
                      showMonthDropdown
                      showYearDropdown
                      onChange={callback}
                    />
                    <CloseIcon sx={CloseButton} onClick={closeCallback} />
                    <Divider orientation="vertical" variant="middle" flexItem />
                  </Grid>
                ))
              }
              <Grid item xs={12} md={8} sx={StyledTableFilter}>
                <Button sx={{ mr: 3 }} onClick={() => setDateFilter('week')}>Last Week</Button>
                <Button sx={{ mr: 3 }} onClick={() => setDateFilter('month')}>Last Month</Button>
                <Button onClick={() => setDateFilter('year')}>Last Year</Button>
              </Grid>
            </Grid>
          </CardContent>
          {dataTotal && (
            <Grid container spacing={6}>
              <Tooltip
                placement="bottom-start"
                variant="soft"
                title={(
                  <Box>
                    {dataTotal.total_info?.total
                      ? Object.entries(dataTotal.total_info.total).map(([key, value]) => (
                        <Typography key={key} sx={{ color: '#fff', textTransform: 'capitalize' }}>
                          {key.replace('_', ' ')}
                          {': '}
                          {value}
                          {' '}
                          EUR
                        </Typography>
                      )) : <Typography sx={{ color: '#fff' }}>
                      No payment for this filters
                    </Typography>}
                  </Box>
                )}
              >
                <Grid item xs={12} md={3} sm={6}>
                  <CardStatistics title="Total" icon={<PollIcon />} stats={dataTotal.total} color="info" />
                </Grid>
              </Tooltip>
              <Tooltip
                placement="bottom-start"
                variant="soft"
                title={(
                  <Box>
                    {dataTotal.total_info?.paid
                      ? Object.entries(dataTotal.total_info.paid).map(([key, value]) => (
                        <Typography key={key} sx={{ color: '#fff', textTransform: 'capitalize' }}>
                          {key.replace('_', ' ')}
                          {': '}
                          {value}
                          {' '}
                          EUR
                        </Typography>
                      )) : <Typography sx={{ color: '#fff' }}>
                        No payment for this filters
                      </Typography>}
                  </Box>
                )}
              >
                <Grid item xs={12} md={3} sm={6}>
                  <CardStatistics title="Paid" icon={<CreditScoreIcon />} stats={dataTotal.paid} />
                </Grid>
              </Tooltip>
              <Tooltip
                placement="bottom-start"
                variant="soft"
                title={(
                  <Box>
                    {dataTotal.total_info?.invoice_unpaid
                      ? Object.entries(dataTotal.total_info.invoice_unpaid).map(([key, value]) => (
                        <Typography key={key} sx={{ color: '#fff', textTransform: 'capitalize' }}>
                          {key.replace('_', ' ')}
                          {': '}
                          {value}
                          {' '}
                          EUR
                        </Typography>
                      )) : <Typography sx={{ color: '#fff' }}>
                      No payment for this filters
                    </Typography>}
                  </Box>
                )}
              >
                <Grid item xs={12} md={3} sm={6}>
                  <CardStatistics title="Invoice unpaid" icon={<CreditCardOffIcon />} stats={dataTotal.invoice_unpaid} color="error" />
                </Grid>
              </Tooltip>
              <Tooltip
                placement="bottom-start"
                variant="soft"
                title={(
                  <Box>
                    {dataTotal.total_info?.estimated_unpaid
                      ? Object.entries(dataTotal.total_info.estimated_unpaid).map(([key, value]) => (
                        <Typography key={key} sx={{ color: '#fff', textTransform: 'capitalize' }}>
                          {key.replace('_', ' ')}
                          {': '}
                          {value}
                          {' '}
                          EUR
                        </Typography>
                      )) : <Typography sx={{ color: '#fff' }}>
                      No payment for this filters
                    </Typography>}
                  </Box>
                )}
              >
                <Grid item xs={12} md={3} sm={6}>
                  <CardStatistics title="Estimated unpaid" icon={<CreditCardOffIcon />} stats={dataTotal.estimated_unpaid} color="error" />
                </Grid>
              </Tooltip>
            </Grid>
          )}
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Box>
          <Tabs
            centered
            value={activeTab}
            aria-label="basic tabs example"
            onChange={handleChangeTab}
            sx={{ mt: 0, pt: 0 }}
          >
            <Tab label="External" sx={{ fontSize: '1.5rem', mr: 5 }} value={EXTERNAL_TAB} />
            <Tab label="Internal" sx={{ fontSize: '1.5rem' }} value={INTERNAL_TAB} />
          </Tabs>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <Box sx={{
            p: 5,
            pb: 3,
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
          >
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
              <TextField
                size="small"
                sx={{
                  mr: 6, mb: 2, minWidth: '300px', maxWidth: '300px'
                }}
                placeholder="Search..."
                onChange={debounce((e) => setSearch(e.target.value), 400)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          <EditableTable
            useHook={activeTab === EXTERNAL_TAB ? useGetExternalLogisticStatisticsTableQuery : useGetInternalLogisticStatisticsTableQuery}
            columns={columns}
            sortByType="DESC"
            sortByActive="id"
            getRowHeight={() => 'auto'}
            tableParams={{
              status: statusFilter,
              order_type: typeFilter,
              search,
              refresh
            }}
            style={{
              '& .MuiDataGrid-columnHeaders': { borderRadius: 0 }
            }}
            handleInvoicesDrawer={handleInvoicesDrawer}
          />
        </Card>
      </Grid>
      <InvoicesDrawer onClose={() => handleInvoicesDrawer(null)} orderId={activeOrderId} orderType={activeTab} refetchTable={handleRefresh} />
    </Grid>
  );
};

export default LogisticStatistics;

import {
  Box, CardContent, Checkbox, Dialog, Divider, FormControlLabel, Grid, InputAdornment, TextField, Typography
} from '@mui/material';
import { Rename } from 'mdi-material-ui';
import React, { useEffect, useState } from 'react';
import Button from '../../../../../components/Button';
import { closeIcon } from '../../../../../components/Drawer/styles';
import { CloseIcon } from '../../../../../components/Icons';
import { formControlLabel } from '../../../../Flights/form/components/ContractInvoiceDrawer/styles';
import { StyledCard } from '../../components/forms/approved/invoices/InvoiceTable';
import { HeaderTypography } from '../../components/forms/approved/invoices/InvoiceTableHeader';

const PriceConfirmationModal = (props) => {
  const { 
    open = false, 
    onClose = () => {},
    prices = [],
    orders = [],
    setValue = () => {},
    getValues = () => {},
    setIsConfirmModal = () => {},
    setIsSaveChangedPrice = () => {}
  } = props;

  const [samePrice, setSamePrice] = useState(false);
  const [deliveryFees, setDeliveryFees] = useState(0);

  const handleSetDeliveryFees = () => {
    const invoiceSum = getValues('invoice_sum');
    const pricesSum = getValues('prices');
    let orderPrices = 0;

    if (pricesSum.length > 1) {
      orderPrices = pricesSum.map(pr => +pr.amount).reduce((a, b) => (a + b));
    } else {
      orderPrices = +pricesSum?.[0]?.amount;
    }

    const difference = Math.abs(invoiceSum - orderPrices);

    setDeliveryFees(difference);
  };
  
  const handleSamePrice = (value) => {
    if (value) {
      const invoiceSum = getValues('invoice_sum');
      let orderPrices = 0;

      if (prices.length > 1) {
        orderPrices = prices.map(pr => pr.amount).reduce((a, b) => (a + b));
      } else {
        orderPrices = prices?.[0]?.amount;
      }
      
      const difference = invoiceSum - orderPrices;

      if (difference > 0) {
        const newOrderAmount = difference / prices.length;
    
        for (let i = 0; i < prices.length; i++) {
          setValue(`prices.[${i}].delivery_price`, newOrderAmount);
        }
      } else {
        for (let i = 0; i < prices.length; i++) {
          setValue(`prices.[${i}].delivery_price`, null);
        }
      }
    } else {
      for (let i = 0; i < prices.length; i++) {
        setValue(`prices.[${i}].delivery_price`, null);
      }
    }
    handleSetDeliveryFees();
    setSamePrice(value);
  };

  const handleSave = () => {
    setIsSaveChangedPrice(true);
    setIsConfirmModal(false);
    onClose();
  };

  const handleClose = () => {
    orders.forEach((item, index) => {
      setValue(`prices.[${index}].amount`, item?.part_count ? (+item?.part_price * +item?.part_count) : (+item?.part_price));
    });

    onClose();
  };

  const handleChangePriceAmount = (value, fieldName) => {
    setValue(fieldName, value);

    handleSetDeliveryFees();
  };
 
  useEffect(() => {
    if (open) {
      handleSetDeliveryFees();
    }
  }, [open]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      maxWidth="xll"
      title="Confirm Prices"
    >
      <Box sx={{ padding: '12px 24px' }}>
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Box />
          <Typography variant="h5">Confirm Prices</Typography>
          <CloseIcon
            onClick={() => onClose()}
            sx={{
              ...closeIcon, position: 'absolute', top: '1rem', right: '1rem' 
            }}
          />
        </Box>

        <Divider />

        <Grid xs={12}>
          <CardContent sx={{
            margin: '0',
            boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#f5f5f5',
            borderRadius: '4px 0px',
          }}
          >
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3}>
                <HeaderTypography variant="body1">Order Number</HeaderTypography>
              </Grid>
              <Grid item xs={3}>
                <HeaderTypography variant="body1">Part Name</HeaderTypography>
              </Grid>
              <Grid item xs={3}>
                <HeaderTypography variant="body1">Part Number</HeaderTypography>
              </Grid>
              <Grid item xs={3}>
                <HeaderTypography variant="body1">Amount</HeaderTypography>
              </Grid>
            </Grid>
          </CardContent>
          {!!prices.length && (prices || []).map((price, index) => (
            <StyledCard>
              <CardContent>
                <Grid container alignItems="center" spacing={2}>
                  {Object.entries({
                    label: price?.label,
                    part_name: price?.part_name,
                    part_number: price?.part_number,
                    amount: price?.amount,
                  }).map(([key, value]) => {
                    if (key === 'amount') {
                      return (
                        <Grid item xs={3}>
                          <Typography
                            sx={{
                              whiteSpace: 'break-spaces',
                              wordBreak: 'break-all'
                            }}
                          >
                            <TextField
                              size="small"
                              value={value}
                              type="number"
                              disabled={samePrice}
                              placeholder="Estimated Price"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Rename sx={{ ...(value && { color: '#626477' }) }} />
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => {
                                handleChangePriceAmount(e.target.value, `prices.[${index}].amount`);
                              }}
                            />
                          </Typography>
                        </Grid>
                      );
                    }

                    return (
                      <Grid item xs={3}>
                        <Typography
                          sx={{
                            whiteSpace: 'break-spaces',
                            wordBreak: 'break-all'
                          }}
                        >
                          {value || ''}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </CardContent>
            </StyledCard>
          ))}
        </Grid>

        <Divider />

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button onClick={handleSave}>Save</Button>
              <Button onClick={handleClose}>Cancel</Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'flex-end' 
            }}
          >
            <Box>
              <FormControlLabel
                checked={samePrice}
                value={samePrice}
                sx={formControlLabel}
                control={<Checkbox />}
                label="Same Price"
                onChange={e => {
                  handleSamePrice(e.target.checked);
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Typography>Delivery fees:</Typography>
              <Typography>{deliveryFees}</Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Typography>Invoice SUM:</Typography>
              <Typography>{getValues('invoice_sum')}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default PriceConfirmationModal;
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import EditIcon from '@mui/icons-material/Edit';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box, Card, CircularProgress, Divider, Tab, Tabs, Typography,
  useMediaQuery
} from '@mui/material';
import FileSaver from 'file-saver';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Button from '../../../components/Button';
import ButtonSelect from '../../../components/ButtonSelect';
import EditableTable from '../../../components/EditableTable';
import TransactionDrawer from '../../../components/TransactionDrawer';
import { useGetTransactionFilesMutation, useUploadTransactionFileMutation } from '../../../store/session';
import {
  useDeletePaymentDataMutation,
  useGetDepartmentsQuery,
  useGetPaymentDataTableQuery,
  usePaymentDataExportExcelMutation,
  useUpdatePaymentDataMutation
} from '../../../store/slices/paymentDataSlice';
import {
  useDeleteSalaryMutation,
  useGetSalariesTableQuery, useGetSalaryFilesMutation,
  useSearchTransactionQuery,
  useUpdateSalaryMutation, useUploadSalaryFileMutation
} from '../../../store/slices/salariesSlice';
import { setFilesName } from '../../../utils/constants/portalFiles';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import { stringifyLocationSearch } from '../../../utils/locationSearch';
import { GreenButton } from '../../Logistics/style';
import { MONTH_SHORT } from '../../MainDeckSchedule/constants';
import { DEFAULT_MONTH, DEFAULT_YEAR } from '../../SabCrewRoster/constants';
import { default as PortalFileDrawer } from '../../TrainingModule/FileDrawer/FileDrawer';
import { PilotContainer, TitleContainer } from '../../TrainingModule/FlightCrew/PilotPage/styles';
import { columns } from './columns';
import FileDrawer from './FileDrawer/fileDrawer';
import GenerateSalaries from './GenerateSalaries/GenerateSalaries';
import ManageDepartments from './ManageDepartments/manageDepartment';
import NewPayment from './NewPayment/newPayment';
import NewSalary from './NewSalary/NewSalary';
import { salaries_columns } from './salaries_columns';
import TransactionModal from './TransactionModal';
import UpdateQuantity from './UpdateQuantity/UpdateQuantity';
import UpdateRate from './UpdateRate/updateRate';

const PaymentData = () => {
  const { data: departmentsList, refetch: refetchDepartmentsList } = useGetDepartmentsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [activeTab, setActiveTab] = useState(0);
  const [getFiles, { isLoading: isLoadingPortalFiles }] = useGetSalaryFilesMutation();
  const [getTransactionFiles, { isLoading: isLoadingTransactionPortalFiles }] = useGetTransactionFilesMutation();
  const [uploadFiles, { isLoading: isAttachLoading }] = useUploadSalaryFileMutation();
  const [uploadTransactionFiles, { isLoading: isAttachTranasctionLoading }] = useUploadTransactionFileMutation();
  const [exportData, { isLoading: isExportLoading }] = usePaymentDataExportExcelMutation();

  const [transactionModalState, setTransactionModalState] = useState({
    isOpen: false,
    config: {
      transaction_id: null,
      file_id: null
    }
  });
  const [currentBlock, setCurrentBlock] = useState(null);
  const [isOpenManageDepartments, setIsOpenManageDepartments] = useState(false);
  const [isOpenNewPayment, setIsOpenNewPayment] = useState(false);
  const [isOpenNewSalary, setIsOpenNewSalary] = useState(false);

  const [isOpenUpdateRate, setIsOpenUpdateRate] = useState(false);
  const [currentPaymentDataRate, setCurrentPaymentDataRate] = useState(null);

  const [isOpenUpdateRateQnt, setIsOpenUpdateRateQnt] = useState(false);
  const [currentSalary, setCurrentSalary] = useState(null);

  const [isOpenGenerate, setIsOpenGenerate] = useState(false);

  const [isOpenFileDrawer, setIsOpenFileDrawer] = useState({
    open: false,
    row: null,
    salary: false
  });
  const [refresh, setRefresh] = useState(false);
  const [year, setYear] = useState(DEFAULT_YEAR);
  const [months, setMonths] = useState(DEFAULT_MONTH);
  const [sortState, setSortState] = useState({ field: 'id', sort: 'ASC' });
  const admin = usePermissions('payment_data_admin');
  const hasPermissions = usePermissions(['payment_data_admin', 'payment_data_access']);
  const exportAllDepartments = usePermissions(['payment_data_export', 'payment_data_admin']);

  const [portalFileDrawerState, setPortalFileDrawerState] = useState({
    isOpen: false,
    type: null,
    fileList: [],
    name: '',
    currentName: '',
    currentId: null
  });
  const [salaryTransactionState, setSalaryTransactionState] = useState({
    isOpen: false,
    transaction_id: null,
    item_id: null,
    type: null,
    fileList: [],
    name: '',
    currentName: '',
    currentId: null
  });

  const maxWidth800px = useMediaQuery('(max-width:800px)');

  const numberMonths = MONTH_SHORT.reduce((total, amount, index) => {
    const monthIndex = [...total, months.includes(amount) && index + 1];

    return monthIndex.filter((e) => e);
  }, []);

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const handleChangeBlock = (_, newValue) => {
    setCurrentBlock(newValue);
  };

  const handleTab = (event, tab) => {
    setActiveTab(tab);
  };

  const handleOpenUpdateRate = (data) => {
    setCurrentPaymentDataRate(data);
    setIsOpenUpdateRate(true);
  };

  const handleCloseUpdateRate = () => {
    setCurrentPaymentDataRate(null);
    setIsOpenUpdateRate(false);
  };

  const handleOpenUpdateQnt = (data) => {
    setCurrentSalary(data);
    setIsOpenUpdateRateQnt(true);
  };

  const handleCloseUpdateQnt = () => {
    setCurrentSalary(null);
    setIsOpenUpdateRateQnt(false);
  };

  const handleOpenSalaryTransaction = (data) => {
    setSalaryTransactionState({
      isOpen: true,
      transaction_id: data.transaction_id,
      item_id: data.item_id,
      created_date: data.created_date,
    });
  };

  const handleOpenTransactionModal = (isOpen = false, file_id = null, transaction_id = null) => {
    setTransactionModalState(prev => ({
      ...prev,
      isOpen,
      config: {
        ...prev.config,
        transaction_id,
        file_id
      }
    }));
  };

  const handleExport = async () => {
    const res = await exportData(stringifyLocationSearch({
      sortDir: sortState?.sort?.toLocaleUpperCase(),
      sortField: sortState?.field,
      ...(!exportAllDepartments && { payment_department_ids: departmentsList?.map(({ id }) => id)?.join(',') })
    }));

    FileSaver.saveAs(res.data, 'payment_data');
  };

  useEffect(() => {
    if (departmentsList) {
      setCurrentBlock(departmentsList[0]?.id);
    }
  }, [departmentsList]);

  const getFileList = async (id, callback) => {
    const res = await getFiles(id);

    if (res?.error) {
      if (res.error.status === 403) {
        toast.error('You do not have permission to perform this action.');
      } else {
        toast.error('Unable to get file list.');
      }

      callback([]);

      return;
    }

    callback(res?.data || []);
  };

  const getTransactionFileList = async (id, type, callback) => {
    const res = await getTransactionFiles({ id, type });

    if (res?.error) {
      if (res.error.status === 403) {
        toast.error('You do not have permission to perform this action.');
      } else {
        toast.error('Unable to get file list.');
      }

      callback([]);

      return;
    }

    callback(res?.data || []);
  };

  const handlePortalFileDrawer = (isOpen, id = null, type = null, name = '', currentName = '', date = null) => {
    setPortalFileDrawerState(prev => ({
      ...prev,
      isOpen,
      type,
      fileList: [],
      currentId: id,
      name,
      currentName,
      date
    }));
    if (id) {
      getFileList(id, (list) => setPortalFileDrawerState(prev => ({
        ...prev,
        isOpen,
        type,
        currentId: id,
        fileList: setFilesName(list),
        name,
        currentName,
        date
      })));
    }
  };

  const handleTransactionPortalFileDrawer = (isOpen, id = null, type = null, name = '', currentName = '') => {
    setSalaryTransactionState(prev => ({
      ...prev,
      isOpen,
      type,
      fileList: [],
      currentId: id,
      name,
      currentName
    }));
    if (id) {
      getTransactionFileList(id, type, (list) => setSalaryTransactionState(prev => ({
        ...prev,
        isOpen,
        type,
        currentId: id,
        fileList: setFilesName(list),
        name,
        currentName
      })));
    }
  };

  const updateFiles = async (id, file, type, callback) => {
    if (!file.target.files[0]) return;

    const toastId = toast.loading('Loading...');
    const formData = new FormData();

    Object.keys(file.target.files)?.forEach((key) => {
      formData.append('upload_files[]', file.target.files[key]);
    });

    const res = await uploadFiles({ id, data: formData });

    if (res?.error) {
      if (res.error.status === 403) {
        toast.error('You do not have permission to perform this action.', {
          id: toastId,
        });
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }

      return;
    }

    toast.success('Success!', {
      id: toastId,
    });

    callback();
    handleRefresh();
  };

  const updateTranasctionFiles = async (id, file, type, callback) => {
    if (!file.target.files[0]) return;

    const toastId = toast.loading('Loading...');
    const formData = new FormData();
    formData.append('file_type_id', type);

    Object.keys(file.target.files)?.forEach((key) => {
      formData.append('upload_files[]', file.target.files[key]);
    });

    const res = await uploadTransactionFiles({ id, data: formData });

    if (res?.error) {
      if (res.error.status === 403) {
        toast.error('You do not have permission to perform this action.', {
          id: toastId,
        });
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }

      return;
    }

    toast.success('Success!', {
      id: toastId,
    });

    callback();
    handleRefresh();
  };

  return (
    <TabContext value={activeTab}>
      <TabList
        onChange={handleTab}
        aria-label="tabs"
        sx={{ bgcolor: 'whitesmoke', minHeight: '96px' }}
        variant="fullWidth"
      >
        <Tab value={0} label="Payment Data" icon={<CalendarViewDayIcon />} />
        <Tab value={1} label="Salaries" icon={<ViewDayIcon />} />
      </TabList>
      <Card sx={{ padding: 3, borderRadius: '0px' }}>
        <Box sx={TitleContainer}>
          <Box sx={maxWidth800px ? {
            display: 'flex',
            flexDirection: 'column',
            gap: 5
          } : {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          >
            <Box sx={PilotContainer}>
              <Typography variant="h3" sx={{ display: 'block' }}>
                {activeTab === 0 ? 'Payment Data' : 'Salaries'}
              </Typography>
            </Box>

            {activeTab === 0 && (
              <Box sx={maxWidth800px ? {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: '100%'
              } : {}}
              >
                <Button
                  sx={{ mr: maxWidth800px ? 0 : '15px', ...(GreenButton) }}
                  disabled={!hasPermissions || !currentBlock}
                  onClick={() => {
                    if (hasPermissions && currentBlock) setIsOpenNewPayment(true);
                  }}
                  title="Create New"
                />

                <Button
                  sx={{ mr: maxWidth800px ? 0 : '15px' }}
                  startIcon={isExportLoading && <CircularProgress size={15} />}
                  disabled={!currentBlock || isExportLoading}
                  onClick={() => handleExport()}
                  title="Export to Excel"
                />
              </Box>
            )}
            {activeTab === 1 && (
              <Box sx={maxWidth800px ? {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: '100%'
              } : {}}
              >
                <Button
                  sx={{ mr: maxWidth800px ? 0 : '15px', ...(GreenButton) }}
                  disabled={!hasPermissions || !currentBlock}
                  onClick={() => {
                    if (hasPermissions && currentBlock) setIsOpenGenerate(true);
                  }}
                  title="Generate Salaries"
                />

                <Button
                  sx={{ mr: maxWidth800px ? 0 : '15px', ...(GreenButton) }}
                  disabled={!hasPermissions || !currentBlock}
                  onClick={() => {
                    if (hasPermissions && currentBlock) setIsOpenNewSalary(true);
                  }}
                  title="Create New"
                />
              </Box>
            )}
          </Box>

        </Box>
        <Divider sx={{ mt: 5 }} />
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'baseline',
        }}
        >
          <Box sx={maxWidth800px ? {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
          } : {
            ...(activeTab === 0 && {
              gridTemplateColumns: '8.5fr 1.5fr',
              width: '100%',
              paddingRight: '0.75rem'
            }),
            display: 'grid',
            alignItems: 'center',
            gap: 0,

          }}
          >
            {departmentsList?.length ? (
              <Tabs
                value={currentBlock}
                onChange={handleChangeBlock}
                variant="scrollable"
                scrollButtons="auto"
              >
                {(departmentsList || []).map((block) => (
                  <Tab
                    label={block?.name}
                    key={block.id}
                    value={block.id}
                  />
                ))}
              </Tabs>
            ) : null}
            {(admin && activeTab === 0) && (
            <Box sx={{
              minHeight: '48px',
              display: 'flex',
              justifyContent: 'end',
              ...(maxWidth800px && {
                justifyContent: 'center',
                padding: '0 0.75rem',
                width: '100%'
              })
            }}
            >
              <Button
                size="small"
                startIcon={<EditIcon />}
                title="Manage Departments"
                onClick={() => setIsOpenManageDepartments(true)}
              />
            </Box>
            )}
          </Box>
        </Box>

        {(activeTab === 1) && (
          <Box sx={{
            backgroundColor: '#dfe6f4',
            borderRadius: '5px 5px 0px 0px',
            padding: '10px'
          }}
          >
            <ButtonSelect
              sx={{
                paddingBottom: '0px'
              }}
              year={year}
              months={months}
              setYear={setYear}
              setMonths={setMonths}
              disabledSendRoster={false}
            />
          </Box>
        )}

        <TabPanel value={0}>
          <Box>
            <EditableTable
              getRowId={(row) => row.id}
              useHook={useGetPaymentDataTableQuery}
              useHookUpdate={useUpdatePaymentDataMutation}
              useHookDelete={useDeletePaymentDataMutation}
              tableParams={{
                payment_department_id: currentBlock || 1,
                refresh
              }}
              getRowHeight={() => 'auto'}
              setSortOut={setSortState}
              sortByType="ASC"
              sortByActive="id"
              columns={columns}
              setIsOpenFileDrawer={setIsOpenFileDrawer}
              hasPermissions={hasPermissions}
              handleOpenUpdateRate={handleOpenUpdateRate}
            />
          </Box>

          <ManageDepartments
            open={isOpenManageDepartments}
            onClose={() => {
              refetchDepartmentsList();
              setIsOpenManageDepartments(false); }}
            refetchDepartmentsList={refetchDepartmentsList}
            hasPermissions={hasPermissions}
          />

          <NewPayment open={isOpenNewPayment} onClose={() => setIsOpenNewPayment(false)} handleRefresh={handleRefresh} currentBlock={currentBlock} />
        </TabPanel>

        <TabPanel value={1}>
          <Box>
            <EditableTable
              getRowId={(row) => row.id}
              useHook={useGetSalariesTableQuery}
              useHookUpdate={useUpdateSalaryMutation}
              useHookDelete={useDeleteSalaryMutation}
              tableParams={{
                payment_department_id: currentBlock || 1,
                year,
                refresh,
                months: numberMonths
              }}
              getRowHeight={() => 'auto'}
              setSortOut={setSortState}
              sortByType="ASC"
              sortByActive="id"
              columns={salaries_columns}
              hasPermissions={hasPermissions}
              handleOpenUpdateRate={handleOpenUpdateRate}
              handleOpenUpdateQnt={handleOpenUpdateQnt}
              setIsOpenFileDrawer={setIsOpenFileDrawer}
              handleFileDrawer={handlePortalFileDrawer}
              handleOpenSalaryTransaction={handleOpenSalaryTransaction}
              handleTransactionPortalFileDrawer={handleTransactionPortalFileDrawer}
            />
          </Box>

          <UpdateQuantity open={isOpenUpdateRateQnt} onClose={() => handleCloseUpdateQnt()} handleRefresh={handleRefresh} salaryData={currentSalary} />
          <PortalFileDrawer
            open={portalFileDrawerState.isOpen}
            onClose={() => handlePortalFileDrawer(false)}
            uploadFile={(file) => updateFiles(portalFileDrawerState.currentId, file, portalFileDrawerState.type, () => handlePortalFileDrawer(true, portalFileDrawerState.currentId, portalFileDrawerState.type, portalFileDrawerState.name, portalFileDrawerState.currentName))}
            title={portalFileDrawerState.name}
            itemName={portalFileDrawerState.currentName}
            itemsList={portalFileDrawerState.fileList}
            hasPermissions={hasPermissions}
            isLoadingFiles={isLoadingPortalFiles}
            deleteFileCallback={handleRefresh}
            isAttachLoading={isAttachLoading}
            customButton
            customButtonFunction={(...arg) => handleOpenTransactionModal(true, ...arg)}
            drawerSx={{
              zIndex: '100'
            }}
          />
          <GenerateSalaries open={isOpenGenerate} onClose={() => setIsOpenGenerate(false)} departmentsList={departmentsList} handleRefresh={handleRefresh} />

          <TransactionModal
            open={transactionModalState.isOpen}
            config={transactionModalState.config}
            onClose={() => handleOpenTransactionModal(false)}
            salaryId={portalFileDrawerState.currentId}
            salaryDate={portalFileDrawerState.date}
            refetchCallback={() => handlePortalFileDrawer(true, portalFileDrawerState.currentId, portalFileDrawerState.type, portalFileDrawerState.name, portalFileDrawerState.currentName)}
          />
        </TabPanel>

        <FileDrawer open={isOpenFileDrawer.open} onClose={() => setIsOpenFileDrawer(false)} currentData={{ userId: isOpenFileDrawer?.row?.id, payment_department_id: isOpenFileDrawer?.row?.payment_department_id, userName: isOpenFileDrawer?.row?.name }} hasPermissions={hasPermissions} />
        <TransactionDrawer
          open={salaryTransactionState.isOpen}
          onClose={() => handleTransactionPortalFileDrawer(false)}
          uploadFile={(file) => updateTranasctionFiles(salaryTransactionState.currentId, file, salaryTransactionState.type, () => handleTransactionPortalFileDrawer(true, salaryTransactionState.currentId, salaryTransactionState.type, salaryTransactionState.name, salaryTransactionState.currentName))}
          handleRefresh={handleRefresh}
          itemData={salaryTransactionState}
          itemName={salaryTransactionState.currentName}
          itemsList={salaryTransactionState.fileList}
          hasPermissions={hasPermissions}
          isLoadingFiles={isLoadingTransactionPortalFiles}
          deleteFileCallback={handleRefresh}
          isAttachLoading={isAttachTranasctionLoading}
          useUpdateMutation={useUpdateSalaryMutation}
          useSearchTransaction={useSearchTransactionQuery}
        />
        <UpdateRate open={isOpenUpdateRate} onClose={() => handleCloseUpdateRate()} handleRefresh={handleRefresh} paymentData={currentPaymentDataRate} />
        <NewSalary open={isOpenNewSalary} onClose={() => setIsOpenNewSalary(false)} handleRefresh={handleRefresh} currentBlock={currentBlock} />
      </Card>
    </TabContext>
  );
};

export default PaymentData;

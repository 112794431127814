import { Box, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import Drawer from '../../../../components/Drawer';
import { useGetExternalOrderInvoicesQuery, useGetInternalOrderInvoicesQuery } from '../../../../store/slices/financeSlice';
import InvoiceCard from '../../../Logistics/OrdersDashboard/components/forms/approved/invoices/InvoiceCard';
import { HeaderTypography } from '../../../Logistics/OrdersDashboard/components/forms/approved/invoices/InvoiceTableHeader';
import { EXTERNAL_TAB, INTERNAL_TAB } from '../constants';

const InvoicesDrawer = ({
  onClose, orderId, orderType
}) => {
  const { data: internalInvoicesData } = useGetInternalOrderInvoicesQuery(orderId, {
    refetchOnMountOrArgChange: true,
    skip: (orderType === EXTERNAL_TAB || !orderId)
  });
  const { data: externalInvoicesData } = useGetExternalOrderInvoicesQuery(orderId, {
    refetchOnMountOrArgChange: true,
    skip: (orderType === INTERNAL_TAB || !orderId)
  });

  const invoicesData = orderType === EXTERNAL_TAB ? externalInvoicesData : internalInvoicesData;

  return (
    <Drawer
      title={`Invoices #${orderId}`}
      open={!!orderId}
      onClose={onClose}
    >
      <Box p={3}>
        {invoicesData && Object.values(invoicesData)?.length ? (
          <>
            <CardContent sx={{
              margin: '0',
              boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.1)',
              backgroundColor: '#f5f5f5',
              borderRadius: '4px 0px',
            }}
            >
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={2}>
                  <HeaderTypography variant="body1"></HeaderTypography>
                </Grid>
                <Grid item xs={3}>
                  <HeaderTypography variant="body1">Invoice File</HeaderTypography>
                </Grid>
                <Grid item xs={4}>
                  <HeaderTypography variant="body1">Status</HeaderTypography>
                </Grid>
                <Grid item xs={2}>
                  <HeaderTypography variant="body1">Proof of Payment</HeaderTypography>
                </Grid>
              </Grid>
            </CardContent>
            {Object.values(invoicesData)?.map((element) => (
              <InvoiceCard key={element.id} invoice={element} deleteAction={false} />
            ))}
          </>
        ) : (
          <Box sx={{
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyItems: 'center'
          }}
          >
            <Typography variant="h5" noWrap>
              No related invoices
            </Typography>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default InvoicesDrawer;

import { PriceCheck } from '@mui/icons-material';
import {
  Box,
  Card,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import React from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Button from '../../../../../../components/Button';
import FormGroup from '../../../../../../form/components/FormGroup';
import { usePermissions } from '../../../../../../utils/hooks/usePermissions';
import { headerBtn } from '../../../../../Invoices/style';
import { content } from '../../../../MyOrders/NewOrder/components/style';
import {
  Filters, GreenButton, RedButton, TextWithIcon
} from '../../../../style';
import { noticeStyle } from '../../../styles';
import { PRICES_STATUS, TRANSACTION_TYPE } from '../new/schema';
import { usePendingApproveForm } from './usePendingApproveForm';

const NewOrderForm = ({ onClose }) => {
  const { order: currentOrder, currencies } = useSelector((state) => state.logistics);
  const { order: internalOrder } = useSelector((state) => state.internalLogistics);
  const location = useLocation();
  const dashboardType = location.pathname.split('/')[3];
  const order = dashboardType === 'orders' ? currentOrder : internalOrder;
  const userAccess = usePermissions('logistics_order_approve');
  const internalOverhaulOrderApprove = usePermissions('internal_logistics_overhaul_order_approve');
  const internalApprover = usePermissions('internal_logistics_order_approve');

  const {
    formMethods,
    handleSubmit,
    onSubmit,
    isLoading
  } = usePendingApproveForm({ order, onClose, dashboardType });

  const { errors } = formMethods.formState;

  if ((dashboardType === 'orders') ? !userAccess : !internalApprover) return <Typography sx={{ margin: '20px 0', textAlign: 'center' }}>You don&apos;t have access.</Typography>;

  return (
    <FormProvider {...formMethods}>
      <form>
        <Card
          sx={{ padding: '0rem 1rem 1rem 1rem' }}
        >
          <Box sx={headerBtn}>
            <Box>
              <Typography variant="h5" noWrap>
                Approve Order
              </Typography>
              <Typography variant="subtitle2">
                Please choose one price.
              </Typography>
            </Box>
          </Box>

          <Grid container>
            {order?.requested_notice && (
            <Grid item xs={12}>
              <FormGroup label="Current Notice" hasError={!!errors.storage}>
                <TextField
                  id="standard-basic"
                  placeholder="You can indicate some important notes"
                  row={6}
                  multiline
                  variant="standard"
                  value={order?.requested_notice}
                  disabled
                  sx={noticeStyle}
                />
              </FormGroup>
            </Grid>
            )}

            <Grid item xs={12}>
              <Box sx={content}>
                <Typography noWrap variant="h6" sx={TextWithIcon}>
                  <PriceCheck />
                  {(order?.part_type === 'Exchange') ? 'Suggestions' : 'Prices'}
                </Typography>

                <Grid
                  container
                  sx={{
                    display: 'flex',
                    backgroundColor: 'white',
                    padding: '15px',
                    borderRadius: '8px'
                  }}
                >

                  <FormGroup hasError={!!errors.price_id}>
                    <Controller
                      name="price_id"
                      control={formMethods.control}
                      render={({ field }) => (
                        <RadioGroup
                          {...field}
                        >
                          {(order?.prices || []).map((price, index) => {
                            const currenciesArray = Array.isArray(currencies) ? currencies : [];
                            const currency = (currenciesArray || [])?.find(curr => curr?.value?.toString() === price?.currency_id?.toString())?.label;
                            const estimatedCurrency = (currenciesArray || [])?.find(curr => +curr?.value === +price?.estimated_delivery_currency_id)?.label;
                            const currentStatus = PRICES_STATUS.find(item => item?.value === price?.status)?.label;
                            const currentTransaction = TRANSACTION_TYPE.find(item => item?.value === price?.transaction_type)?.label;
                            if (['Repair_in', 'Overhaul_in'].includes(order?.part_type) && price.amount === 1) {
                              return null;
                            }

                            return (
                              <FormControlLabel
                                key={price.id}
                                value={price.id}
                                control={<Radio color="success" />}
                                label={(
                                  <>
                                    {index !== 0 && <Divider sx={{ width: '100%' }} />}
                                    <Box>
                                      {((order?.part_type === 'Other') || (price.amount !== 1)) && (
                                      <Typography>
                                        <b>
                                          {!['Overhaul_out', 'Repair_out', 'Repair_in', 'Overhaul_in'].includes(order?.part_type) ? 'Price' : 'Estimated Price'}
                                        </b>
                                        {' '}
                                        {price.amount}
                                        {' '}
                                        {currency || ''}
                                      </Typography>
                                      )}
                                      {price?.supplier_data && (
                                      <Typography>
                                        <b>
                                          {order?.part_type === 'delivery' ? 'Delivery Operators:' : ' Vendor:'}
                                        </b>
                                        {' '}
                                        {price?.supplier_data?.name ?? 'N/A'}
                                      </Typography>
                                      )}
                                      {currentStatus && (
                                      <Typography>
                                        <b>
                                          Status:
                                        </b>
                                        {' '}
                                        {currentStatus}
                                      </Typography>
                                      )}
                                      {currentTransaction && (
                                      <Typography>
                                        <b>
                                          Transaction Type:
                                        </b>

                                        {' '}
                                        {currentTransaction}
                                      </Typography>
                                      )}
                                      <Typography>
                                        <b>
                                          Suggested:
                                        </b>

                                        {' '}
                                        {price?.suggested === 0 ? 'No' : 'Yes'}
                                      </Typography>
                                      {price?.carrier && (
                                      <Typography>
                                        <b>
                                          Carrier:
                                        </b>

                                        {' '}
                                        {price?.carrier}
                                      </Typography>
                                      )}
                                      {price?.address_from && (
                                      <Typography>
                                        <b>
                                          From:
                                        </b>

                                        {' '}
                                        {price?.address_from}
                                      </Typography>
                                      )}
                                      {price?.address_to && (
                                      <Typography>
                                        <b>
                                          To:
                                        </b>

                                        {' '}
                                        {price?.address_to}
                                      </Typography>
                                      )}
                                      {price?.estimated_date && (
                                        <Typography>
                                          <b>
                                            Estimated Date:
                                          </b>

                                          {' '}
                                          {price?.estimated_date?.split(' ')?.[0] || price?.estimated_date}
                                        </Typography>
                                      )}
                                      {price?.estimated_delivery_price && (
                                        <Typography>
                                          <b>
                                            Estimated Delivery Price:
                                          </b>

                                          {' '}
                                          {price?.estimated_delivery_price}
                                          {' '}
                                          {estimatedCurrency || ''}
                                        </Typography>
                                      )}
                                    </Box>
                                  </>
)}
                              />
                            );
                          })}
                        </RadioGroup>
                      )}
                    />
                  </FormGroup>

                </Grid>
                <Grid>
                  <FormGroup
                    label="Price Notice"
                    hasError={!!errors.storage}
                  >
                    <Controller
                      name="approver_notice"
                      control={formMethods.control}
                      render={({ field }) => (
                        <TextField
                          id="outlined-multiline-static"
                          placeholder="You can indicate some important notes"
                          row={6}
                          multiline
                          {...field}
                          sx={noticeStyle}
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Divider />
              <Box sx={{ ...(Filters), padding: '0px', justifyContent: 'space-between' }}>
                <Box>
                  <Button
                    sx={{ mt: 2, ...(RedButton) }}
                    variant="contained"
                    size="large"
                    title="Cancel"
                    onClick={onClose}
                  />
                </Box>
                <Box>
                  <Button
                    sx={{ mt: 2, ...(GreenButton) }}
                    variant="contained"
                    size="large"
                    disabled={!isLoading || ((dashboardType === 'delivery') && ((order?.part_type === 'Overhaul_out') || (order?.part_type === 'Repair_out') || (order?.part_type === 'Overhaul_in') || (order?.part_type === 'Repair_in')) && !internalOverhaulOrderApprove)}
                    onClick={handleSubmit(onSubmit)}
                    title="Submit"
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </form>
    </FormProvider>
  );
};

export default NewOrderForm;

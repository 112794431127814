import { yupResolver } from '@hookform/resolvers/yup';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box, Card, CardContent, CardHeader, Divider, Switch, Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Button from '../../../../components/Button';
import Upload from '../../../../components/Upload';
import Autocomplete from '../../../../form/components/Autocomplete/Autocomplete';
import DatePicker from '../../../../form/components/DatePicker';
import FieldError from '../../../../form/components/FieldError';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import ServerAutocomplete from '../../../../form/components/ServerAutocomplete';
import {
  useCreateInvoiceDocMutation,
  useDownloadInvoiceMutation,
  useGetCurrencyQuery, useGetPurposesQuery
} from '../../../../store/session';
import { useGetProformaListQuery } from '../../../../store/slices/invoiceManagementSlice';
import { useGetSupplierInfoQuery, useSearchSupplierQuery } from '../../../../store/slices/logisticsSlice';
import { getOptions } from '../../../../utils/getOptions';
import { usePermissions } from '../../../../utils/hooks/usePermissions';
import { numberUnFormat } from '../../../../utils/number';
import { setDateValue, validateDatePickerValue } from '../../../../utils/setDateValue';
import InvoiceList from './components/InvoiceList';
import { defaultValues, schema } from './schema';
import {
  block, button, buttonBlock, documentForm, file, fileName, invoiceList, label
} from './style';

const InvoicesForm = () => {
  const [nameFile, setNameFile] = useState();
  const [supplier, setSupplier] = useState(null);
  const [proformaPath, setProformaPath] = useState(null);
  const maxWidth800px = useMediaQuery('(max-width:800px)');

  // Access
  const supplierPermission = usePermissions('logistics_suppliers_read');
  const logistPermission = usePermissions('logist');
  const internalLogistPermission = usePermissions('internal_logist');
  
  const { data: currency } = useGetCurrencyQuery({
    refetchOnMountOrArgChange: true
  });
  const { data: purpose } = useGetPurposesQuery({
    refetchOnMountOrArgChange: true
  });
  const { data: proforma, refetch } = useGetProformaListQuery({ type: 'regular' }, {
    refetchOnMountOrArgChange: true
  });

  const [sendInvoice] = useCreateInvoiceDocMutation();
  const [openInvoice, { isLoading: isOpeningProforma }] = useDownloadInvoiceMutation();

  const currencyOptions = getOptions(currency);
  const purposeOptions = getOptions(purpose);
  const proformaOptions = (proforma || []).map(vlv => ({
    value: vlv?.id,
    label: vlv?.invoice_number ? `${vlv?.id} - ${vlv.invoice_number}` : vlv?.id,
    proformaPath: vlv?.invoice_path,
    data: vlv
  }));

  const {
    handleSubmit,
    setValue,
    trigger,
    reset,
    control,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { data: getSupplier, isLoading } = useGetSupplierInfoQuery({ id: watch('supplier_id') }, {
    refetchOnMountOrArgChange: true,
    skip: !watch('supplier_id')
  });

  const uploadFile = (e) => {
    setNameFile(e.target.files[0].name);

    setValue('file', e.target.files[0]);
  };
 
  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const formData = new FormData();

    formData.append('description', data.description);
    formData.append('invoice_number', data.invoice_number);
    formData.append('invoice_name', data.invoice_name);
    formData.append('file', data.file);
    formData.append('invoice_sum', data.invoice_sum);
    formData.append('currency_id', data.currency_id);
    formData.append('payment_purpose_id', data.payment_purpose_id);
    formData.append('due_payment_date', data.due_payment_date);
    formData.append('payment_type', 'request'); // By Default
    formData.append('predicted_invoice_sum', data.predicted_invoice_sum);
    formData.append('is_proforma', data.is_proforma ? 1 : 0);
    if (supplier) formData.append('supplier_id', supplier?.data?.id);
    if (data.parent_id && !data.is_proforma) {
      formData.append('parent_id', data.parent_id);
    }

    const res = await sendInvoice(formData);

    if (res.error) {
      toast.error(res.error.data?.message, {
        id: toastId,
      });

      return;
    }

    toast.success('The invoice has been sent!', {
      id: toastId,
    });

    reset();
    refetch();

    setValue('payment_purpose', {});
    setValue('parent_id', null);
    setValue('currency', {});
    setValue('payment_purpose_id', {});
    setValue('currency_id', {});
    document.getElementById('file-upload').value = '';
    setNameFile(null);
  };

  const handleSetProforma = (data) => {
    const currentPurpose = (purposeOptions || [])?.find(item => +item?.value === data?.data?.payment_purpose_id);

    const proformaData = { 
      ...data?.data,
      currency: currencyOptions.find(vlv => (+vlv?.value === +data?.data?.currency_id)),
      ...(!!currentPurpose && { payment_purpose: currentPurpose }),
      ...(!!data?.data?.due_payment_date && { due_payment_date: data?.data?.due_payment_date?.split(' ')?.[0] || data?.data?.due_payment_date })
    };

    if (proformaData) {
      delete proformaData?.id;
      delete proformaData?.invoice_path;

      Object.entries(proformaData || {}).map(([key, value]) => setValue(key, value));
    }
  };

  const handleOpenProforma = async (id) => {
    const toastId = toast.loading('Opening...');

    if (typeof id === 'string') {
      toast.error('Document cannot be loaded for preview!', {
        id: toastId,
      });

      return;
    }
    const res = await openInvoice(id);

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    if (res.data instanceof Blob) {
      // Create a URL for the Blob
      const fileURL = URL.createObjectURL(res.data);
      window.open(fileURL, '_blank');
    } else {
      // If res.data is already a URL
      window.open(res.data, '_blank');
    }

    toast.success('Success!', {
      id: toastId,
    });
  };

  useEffect(() => {
    if (getSupplier) {
      setSupplier({
        value: getSupplier?.id,
        label: getSupplier?.name,
        data: getSupplier
      });
      setValue('invoice_name', getSupplier?.name);
    }
  }, [getSupplier]);

  return (
    <Card sx={{ mt: 5, border: '1px solid whitesmoke' }}>
      <CardHeader title="Invoice Request" sx={maxWidth800px ? { paddingLeft: '15px', paddingRight: '0' } : {}} />
      <CardContent sx={{ mt: 5, ...(maxWidth800px && { paddingLeft: '0', paddingRight: '0' }) }}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', ...(maxWidth800px && { flexDirection: 'column' }) }}>
          <Box sx={{ ...documentForm, ...(maxWidth800px && { width: '100%' }) }}>

            <Box sx={file}>
              <FormGroup sx={label} required label="File" hasError={!!errors.file}>
                <Controller
                  name="file"
                  control={control}
                  render={({ field }) => (
                    <Upload
                      {...field}
                      accept=".doc,.docx,image/*,.pdf"
                      handleUpload={(e) => {
                        field.onChange(e);
                        uploadFile(e);
                      }}
                      title="Upload file"
                    />
                  )}
                />
                <FieldError error={errors.file} />
              </FormGroup>
              <Typography sx={fileName}>{nameFile}</Typography>
            </Box>

            <Box sx={block}>
              <FormGroup label="Proforma" hasError={!!errors?.is_proforma}>
                <Controller
                  name="is_proforma"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      checked={field.value}
                      onChange={() => {
                        setValue('is_proforma', !watch('is_proforma'));
                      }}
                    />
                  )}
                />

                <FieldError error={errors?.is_proforma} />
              </FormGroup>
            </Box>

            <Box sx={block}>
              <FormGroup sx={label} label="Description" required hasError={!!errors.description}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      rows={4}
                      multiline
                      variant="filled"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
                <FieldError error={errors.description} />
              </FormGroup>
            </Box>

            <Box sx={{ ...block, position: 'relative' }}>
              <FormGroup sx={label} label="Invoice Number" required hasError={!!errors.invoice_number}>
                <Controller
                  name="invoice_number"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      sx={{ width: '100%' }}
                    />
                  )}
                />
                <FieldError error={errors.invoice_number} />
              </FormGroup>
            </Box>

            <Box sx={{ ...block, position: 'relative' }}>
              <FormGroup sx={label} label="Supplier" required hasError={!!errors.invoice_name}>
                <Controller
                  name="invoice_name"
                  control={control}
                  render={({ field }) => {
                    if ((!logistPermission && supplierPermission) || (!internalLogistPermission && supplierPermission)) {
                      return (
                        <ServerAutocomplete
                          value={supplier}
                          withData
                          placeholder="Search suppliers by name or email"
                          searchQueryFn={useSearchSupplierQuery}
                          queryParams={{ type: 'operator' }}
                          handleSelect={(value) => {
                            field.onChange(value?.label);
                            setSupplier(value);
                          }}
                        />
                      );
                    }

                    return (
                      <Input
                        {...field}
                        sx={{ width: '100%' }}
                      />
                    );
                  }}
                />
                <FieldError error={errors.invoice_name} />
              </FormGroup>
            </Box>

            {((logistPermission && supplierPermission) || (internalLogistPermission && supplierPermission)) && (
            <Box sx={{ ...block, position: 'relative' }}>
              <FormGroup sx={label} label="Supplier Manual" required hasError={!!errors.invoice_name}>
                <Controller
                  name="invoice_name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      sx={{ width: '100%' }}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        if (supplier) {
                          setSupplier(null);
                        }
                      }}
                    />
                  )}
                />
                <FieldError error={errors.invoice_name} />
              </FormGroup>
            </Box>
            )}

            <Box sx={block}>
              <FormGroup sx={label} label="Deadline Payment" hasError={!!errors.due_payment_date}>
                <Controller
                  name="due_payment_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="YYYY-MM-DD"
                      dateFormat="yyyy-MM-dd"
                      showMonthDropdown
                      showYearDropdown
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setDateValue(e, setValue, 'due_payment_date', 'YYYY-MM-DD');
                      }}
                      value={validateDatePickerValue(field.value)}
                    />
                  )}
                />
                <FieldError error={errors.due_payment_date} />
              </FormGroup>
            </Box>

            <Box sx={block}>
              <FormGroup sx={label} label={watch('is_proforma') ? 'Proforma SUM' : 'Invoice SUM'} required hasError={!!errors.invoice_sum}>
                <Controller
                  name="invoice_sum"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      sx={{ width: '100%' }}
                      onChange={(e) => {
                        setValue('invoice_sum', numberUnFormat(e.target.value, true));
                      }}
                    />
                  )}
                />
                <FieldError error={errors.invoice_sum} />
              </FormGroup>
            </Box>

            {!!watch('is_proforma') && (
              <Box sx={block}>
                <FormGroup sx={label} label="Invoice SUM" required hasError={!!errors.predicted_invoice_sum}>
                  <Controller
                    name="predicted_invoice_sum"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        sx={{ width: '100%' }}
                        onChange={(e) => {
                          setValue('predicted_invoice_sum', numberUnFormat(e.target.value, true));
                        }}
                      />
                    )}
                  />
                  <FieldError error={errors.predicted_invoice_sum} />
                </FormGroup>
              </Box>
            )}

            <Box sx={block}>
              <FormGroup sx={label} required label="Currency" hasError={!!errors.currency}>
                <Controller
                  name="currency"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      value={field.value ? currencyOptions?.filter((item) => item.value === field?.value?.value)[0] : null}
                      options={currencyOptions}
                      size="small"
                      placeholder="Please select a currency"
                      onChange={(e, value ) => {
                        field.onChange(value);
                        setValue('currency', value || null);
                        setValue('currency_id', value?.value || null);
                        trigger('currency', value);
                      }}
                    />
                  )}
                />
                <FieldError error={errors.currency} />
              </FormGroup>
            </Box>

            <Box sx={block}>
              <FormGroup sx={label} required label="Payment Purpose" hasError={!!errors.payment_purpose}>
                <Controller
                  name="payment_purpose"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      value={field?.value ? purposeOptions?.filter((item) => item?.value === field?.value?.value)?.[0] : null}
                      options={purposeOptions}
                      size="small"
                      placeholder="Please select a purpose"
                      onChange={(e, value ) => {
                        field.onChange(e);
                        setValue('payment_purpose', value || null);
                        setValue('payment_purpose_id', value?.value || null);
                        trigger('payment_purpose', value);
                      }}
                    />
                  )}
                />
                <FieldError error={errors.payment_purpose} />
              </FormGroup>
            </Box>

            {!watch('is_proforma') && (
              <Box sx={block}>
                <FormGroup
                  sx={label}
                  label={(
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <Typography>Proforma</Typography>
                      {proformaPath && <OpenInNewIcon sx={{ cursor: 'pointer' }} onClick={() => !isOpeningProforma && handleOpenProforma(proformaPath)} />}
                    </Box>
                  )}
                  hasError={!!errors.parent_id}
                >
                  <Controller
                    name="parent_id"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        value={field.value ? proformaOptions.filter(vlv => (vlv.value === field.value))?.[0] : null}
                        options={proformaOptions}
                        withData
                        size="small"
                        placeholder="Search Proforma by ID or Number"
                        onChange={(e, value ) => {
                          field.onChange(+value?.value || null);
                          setProformaPath(value?.proformaPath?.includes('.doc') ? value?.proformaPath : (+value?.value || null));
                          handleSetProforma(value);
                        }}
                      />
                    )}
                  />
                  <FieldError error={errors.parent_id} />
                </FormGroup>
              </Box>
            )}

            <Box sx={buttonBlock}>
              <Button sx={button} title="Send" type="submit" />
            </Box>
          </Box>

          <Divider orientation="vertical" variant="middle" flexItem />

          <Box sx={{ ...invoiceList, ...(maxWidth800px && { width: '100%' }) }}>
            <InvoiceList />
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default InvoicesForm;
